import { type DtoProduct } from '@lp-lib/api-service-client/public';

import { GameCoverClip } from '../../../src/components/Game/GamePackCoverPres';
import { AnonHeaderV2 } from '../AnonHeader';
import { PageLoadingBar } from '../PageLoadingBar';

export function AnonPublicLibraryLayout(props: {
  product?: DtoProduct | null;
  children: React.ReactNode;
  contentKey?: string;
  bookDemoButton?: React.ReactNode;
  secondaryNav?: React.ReactNode;
}) {
  const { product, children, contentKey, secondaryNav } = props;

  return (
    <div className='w-full h-full fixed flex flex-col'>
      <PageLoadingBar color='#f11946' />

      <AnonHeaderV2 product={product} bookDemoButton={props.bookDemoButton} />

      {secondaryNav}

      <div
        // In client-side navigation, the scroll position is not reset.
        // This is a workaround to reset the scroll position when navigating to a new page.
        key={contentKey}
        className='relative w-full flex-1 overflow-auto bg-modal text-white scrollbar'
      >
        {children}
      </div>

      <GameCoverClip id='game-cover-clip' />
    </div>
  );
}
