import { Link, useLocation } from '@remix-run/react';
import { type ReactNode, useState } from 'react';
import { $path } from 'remix-routes';
import { useHydrated } from 'remix-utils/use-hydrated';

import { type DtoProduct } from '@lp-lib/api-service-client/public';

import { ArrowDownIcon } from '../../src/components/icons/Arrows';
import { ChatSingleBubbleIcon } from '../../src/components/icons/Chat/ChatBubbleIcon';
import {
  DefaultLogoIcon,
  LunaParkLogo,
} from '../../src/components/icons/LogoIcon';
import { SlackIcon } from '../../src/components/icons/SlackIcon';
import { useInstance } from '../../src/hooks/useInstance';
import { makeMarketLink } from '../../src/utils/makeLinks';
import { AnonNav } from './AnonNav';
import { HelpWidget } from './HelpWidget/HelpWidget';
import { ContentLoader } from './SSRCompatible/ContentLoader';

type HeaderLink = {
  name: ReactNode;
  href?: string;
  items?: HeaderLink[];
};

function useHeaderLinks(): HeaderLink[] {
  return useInstance(() => [
    {
      name: 'Explore Events',
      href: '/explore',
    },
    {
      name: 'Products',
      href: '#',
      items: [
        {
          name: (
            <span className='flex items-center gap-2'>
              <DefaultLogoIcon className='w-3.5 h-3.5 transform scale-150' />
              <span>Platform</span>
            </span>
          ),
          href: makeMarketLink('/platform/'),
        },
        {
          name: (
            <span className='flex items-center gap-2'>
              <SlackIcon className='w-3.5 h-3.5' />
              <span>Slack Toolkit</span>
            </span>
          ),
          href: makeMarketLink('/slack-toolkit/'),
        },
      ],
    },
    {
      name: 'Pricing',
      href: makeMarketLink('/pricing/'),
    },
  ]);
}
function HeaderNavLink(props: HeaderLink) {
  const location = useLocation();
  const isParent = 'items' in props;
  const isActive = isParent ? false : location.pathname === props.href;

  const linkClassName =
    'h-full flex items-center gap-1 transform-gpu transition-colors duration-300 hover:text-red-006 cursor-pointer';

  return (
    <div className='relative group h-full'>
      <a
        href={props.href}
        className={`${linkClassName} ${
          isActive ? 'border-b-2 border-white' : ''
        }`}
      >
        {props.name}
        {isParent && <ArrowDownIcon />}
      </a>
      {isParent && (
        <ul className='hidden absolute top-12 transform-gpu left-1/2 -translate-x-1/2 z-5 bg-dark-gray border border-secondary w-40 h-25 rounded-2xl group-hover:flex flex-col justify-around py-4'>
          {props.items?.map((item, i) => (
            <li key={i} className='pl-4 w-full'>
              <a href={item.href} className={`${linkClassName}`}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export function BookDemoButton(props: {
  btnStyle?: 'btn-delete' | 'btn-secondary';
  height?: `h-${number}`;
}) {
  return (
    <Link
      to={'https://meetings.hubspot.com/arlen-marmel/general-meeting-rb'}
      className={` rounded flex items-center justify-center font-semibold font-Montserrat w-37.5 ${
        props.height ?? 'h-8'
      } ${props.btnStyle ?? 'btn-delete'}`}
      target='_blank'
    >
      Book a Demo
    </Link>
  );
}

function SignInOrHomeLink(props?: { homePath?: string }) {
  const isHydrated = useHydrated();

  const hasToken = isHydrated ? !!localStorage.getItem('token') : false;

  return isHydrated ? (
    <Link
      to={hasToken ? props?.homePath ?? '/home' : '/login'}
      className='hover:text-red-006 transform-gpu transition-colors duration-300'
    >
      {hasToken ? 'Home' : 'Sign in'}
    </Link>
  ) : (
    <ContentLoader
      uniqueKey='anon-header-sign-in-loader'
      speed={2}
      width={50}
      height={20}
      viewBox='0 0 50 20'
      backgroundColor='#222'
      foregroundColor='#333'
    >
      <rect x='0' y='0' rx='3' ry='3' width='50' height='20' />
    </ContentLoader>
  );
}

export function AnonHeader(props: {
  product: DtoProduct | null | undefined;
  bookDemoButton?: ReactNode;
}) {
  const [openHelpWidget, setOpenHelpWidget] = useState(false);
  const links = useHeaderLinks();
  return (
    <header
      className={`w-full flex-shrink-0 h-15 bg-black border-b border-secondary flex items-center justify-center text-white font-Montserrat`}
    >
      <div className='flex items-center justify-between w-full h-full max-w-360 px-7.5'>
        <div className='h-full flex items-center gap-7.5 text-sms'>
          <a href={`${makeMarketLink('')}`}>
            <LunaParkLogo className='w-27 h-7.5 mr-4' />
          </a>
          {links.map((link, i) => (
            <HeaderNavLink key={i} {...link} />
          ))}
          <button
            type='button'
            className='h-full flex items-center gap-1 hover:text-red-006 transform-gpu transition-colors duration-300'
            onClick={() => setOpenHelpWidget(true)}
          >
            <ChatSingleBubbleIcon />
            <div>Live Chat</div>
          </button>
        </div>

        <div className='flex items-center gap-4 text-sms'>
          <SignInOrHomeLink />
          {props.bookDemoButton || <BookDemoButton />}
        </div>
      </div>

      <HelpWidget open={openHelpWidget} setOpen={setOpenHelpWidget} />
    </header>
  );
}

export function AnonHeaderV2(props: {
  product: DtoProduct | null | undefined;
  bookDemoButton?: ReactNode;
}) {
  return (
    <header
      className={`
        w-full flex-none h-14 bg-black border-b border-secondary 
        flex items-center justify-center text-white px-20`}
    >
      <div className='flex items-center justify-between w-full h-full'>
        <div className='h-full flex items-center gap-7.5 text-sms'>
          <a href={`${makeMarketLink('')}`}>
            <LunaParkLogo className='w-27 h-7.5 mr-4' />
          </a>

          <AnonNav />
        </div>

        <div className='flex items-center gap-5 text-sms'>
          <SignInOrHomeLink homePath={$path('/home')} />
          {props.bookDemoButton || <BookDemoButton />}
        </div>
      </div>
    </header>
  );
}
